<script lang="js">
import Swal from 'sweetalert2'
import VButton from '@/components/Button.vue';
import VIcon from '@/components/Icon.vue';
import VTextbox from '@/components/Textbox.vue';
import VUserPassword from '@/components/UserPassword.vue';

import Terms from '@/components/Terms.vue';
import Conditions from '@/components/Conditions.vue';
import VUserAccount from '@/components/VirtualCardUserAccount.vue';

export default {
  name: 'VirtualCardSignUp',
  components: {
    VButton,
    VIcon,
    VTextbox,
    VUserAccount,
    VUserPassword,
    Terms,
    Conditions
  },
  data() {
    return {
      showTerms: false,
      showConditions: false,
      codeSended: '',
      emailValue: '',
      isEmailDisabled: false,
      isConfirmEmailDisabled: false
    }
  },
  beforeMount() {
    this.emailValue = this.user.email;
    this.checkInputEmailValue()
  },
  mounted() {
    if (this.user.tipo !== 'PJ' && this.user.tipo !== 'PF' && !this.codeSended) {
      this.$router.push({ name: 'signIn' });
      Swal.fire({
        title: 'Link inválido ou vencido',
        html: `<p style="text-align: left; font-size: 16px;">
          O seu link para desbloqueio está vencido ou inválido.
          Caso já tenha usado esse link anteriormente e desbloqueado
          o seu cartão, acesse o site para ver seu saldo e utilizar seu cartão.
          Caso não tenha desbloquado seu cartão ainda, por favor, entre em contato com o nosso atendimento.
        <p>`,
        confirmButtonText: 'Entendi'
      })
    }
    this.user.telefones = {}
    this.user.enderecos = {}
    // this.user.email = ''
    this.user.termsAndConditionsTokens = []
    this.user.senha = '';
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  created() {
    this.codeSended = this.$store.getters.getCardUnlockCode;
  },
  watch: {
    showTerms(val) {
      if (val) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    },
    showConditions(val) {
      if (val) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    },
  },
  methods: {
    checkInputEmailValue() {
      if (this.emailValue) {
        this.isEmailDisabled = true;
        this.isConfirmEmailDisabled = true;
      } else if (!this.emailValue) {
        this.isEmailDisabled = false;
        this.isConfirmEmailDisabled = false;
      }
    },
    async signUp() {
      if (!navigator.onLine) {
        this.$store.commit('message', { type: 'error', text: 'Você não possui internet ou a rede local bloqueou o site.' })
        return false
      }
      if (!this.$store.state.formLoading) {
        this.$store.commit('formLoading', true)
        const phone = document.getElementsByName('account.phone')[0].value
        console.log(this.isValidPhone(phone))
        if (!this.isValidPhone(phone)) {
          this.$validator.errors.add({ msg: 'Número de telefone inválido', field: 'account.phone' });
          this.$store.commit('formLoading', false);
          setTimeout(() => {
            this.$validator.errors.remove('account.phone');
          }, 1500);
          // set focus on phone input by name account.phone
          document.getElementsByName('account.phone')[0].focus();
          return false;
        } this.$validator.errors.remove('account.phone');

        this.$validator.validateAll().then((valid) => {
          if (valid && this.validatePassword()) {
            const userDocumentType = this.user.tipo === 'PF' ? 'cpf' : 'cnpj';
            let userData = {}
            let streetNumberInteger = parseInt(this.user.enderecos.numero.replace(/[^/0123456789]+/g, ''), 10)
            if (!(streetNumberInteger >= 0)) {
              streetNumberInteger = 0
            }
            const nameTest = this.user.nome
            if (nameTest.toString().replace(/[0-9]+/ig, '') === '') {
              this.$validator.errors.add({ msg: 'Insira um nome válido', field: 'account.name' })
              return false
            }
            if (this.user.tipo === 'PF') {
              userData = {
                codeSended: this.codeSended,
                name: this.user.nome,
                motherName: this.user.nomeMae,
                document: this.user[userDocumentType],
                birthDate: this.user.dataNascimento,
                address: {
                  idAddressType: 1,
                  street: this.user.enderecos.logradouro.substring(0, 39),
                  number: `${streetNumberInteger}`,
                  zipCode: this.user.enderecos.cep,
                  city: this.user.enderecos.cidade,
                  neighborhood: this.user.enderecos.bairro.substring(0, 39),
                  federativeUnit: this.user.enderecos.uf,
                  country: 'Brasil',
                  complement: this.user.enderecos.complemento,
                  referencePoint: ''
                },
                phone: {
                  idPhoneType: 18,
                  areaCode: this.user.telefones.ddd,
                  number: this.user.telefones.numeroTelefone,
                },
                password: this.user.senha,
                email: this.user.email,
                cardNumber: this.getCardNumberFromCache()
              }
            } else { // type PJ
              userData = {
                codeSended: this.codeSended,
                tipo: this.$store.getters.getDocumentType,
                nome: this.user.nome,
                cnpj: this.user.cnpj,
                dataNascimento: this.user.birthDate,
                pessoaDetalhes: {
                  email: this.user.email,
                  nomeEmpresa: this.user.nome
                },
                endereco: {
                  cep: this.user.enderecos.cep,
                  logradouro: this.user.enderecos.logradouro.substring(0, 39),
                  numero: streetNumberInteger,
                  complemento: this.user.enderecos.complemento,
                  pontoReferencia: '',
                  bairro: this.user.enderecos.bairro.substring(0, 39),
                  cidade: this.user.enderecos.cidade,
                  uf: this.user.enderecos.uf,
                  pais: 'Brasil'
                },
                telefone: {
                  idTipoTelefone: 1,
                  ddd: this.user.telefones.ddd,
                  telefone: this.user.telefones.numeroTelefone,
                  ramal: ''
                },
                password: this.user.senha,
                cardNumber: this.getCardNumberFromCache()
              }
            }
            const addressUrlApi = '/users/create-virtualcard-phone-process';

            this.postCreateUser(addressUrlApi, userData);
          } else {
            if (!this.validatePassword()) {
              this.$store.commit('message', { type: 'error', text: 'A senha deve conter no mínimo 10 caracteres, uma letra maiúscula, uma letra minúscula, um número e dois caracteres especiais.' });
            }
            this.$store.commit('formLoading', false);
          }
        });
      } else this.$store.commit('formLoading', false);
    },
    isValidPhone(value) {
      const phone = value.replace(/\D/g, '');
      if (/^(\+?55\s?)?0([1-9]{2}|[2-9]{1}[1-9]{1}|\([1-9]{2}\)|\([2-9]{1}[1-9]{1}\))\s?(9)\s?(\d{4})-?(\d{4})$/g.test(phone)) {
        return !/00000000|11111111|22222222|33333333|44444444|55555555|66666666|77777777|88888888|99999999/.test(phone);
      } return false;
    },
    async makeLogin() {
      const userDocumentType = this.user.tipo === 'PF' ? 'cpf' : 'cnpj';
      await this.$http.post(`${process.env.VUE_APP_HUB_BASE_URL2}/users/login`, { email: this.user[userDocumentType], senha: this.user.senha })
        .then(({ data }) => {
          if (data.emailToCode) {
            this.showEmailAndPhone(data.emailToCode, data.phoneNumber);
          }
          if (data.token) {
            this.$cookies.set('token', data.token, '1d');
            const { redirect } = this.$route.query
            if (redirect) this.$router.push({ path: redirect });
            else this.$router.push({ name: 'my-cards' });
          } else if (!data.ok) {
            this.$validator.errors.add({ msg: data.error, field: 'email' });
          }
        })
        .then(() => this.$store.commit('formLoading', false));
    },

    async showEmailAndPhone(emailToCode, phoneNumber) {
      const inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            email: `E-mail: ${emailToCode}`,
            phone: `Celular: ${phoneNumber}`
          })
        }, 1000)
      })
      await Swal.fire({
        title: 'Código de Acesso',
        input: 'radio',
        html: '<p class="px-5">Selecione abaixo como deseja receber o seu código para acesso:</p>',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        closeOnConfirm: false,
        closeOnCancel: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
        inputOptions,
        customClass: {
          text: 'px-5',
        },
        customStyle: {
          text: 'font-size: 18px',
          label: 'font-size: 18px',
          radio: 'font-size: 18px',
        },
        inputValidator: (value) => {
          if (!value) {
            Swal.fire({
              title: 'Seleção obrigatória',
              text: 'É obrigatório a seleção de uma das opções para receber o código de login. Tente novamente, por favor.',
              type: 'info',
              confirmButtonText: 'Ok'
            })
          } else {
            const userDocumentType = this.user.tipo === 'PF' ? 'cpf' : 'cnpj';
            this.$http.post((`${process.env.VUE_APP_HUB_BASE_URL2}/users/login`), { email: this.user[userDocumentType], senha: this.user.senha, selectMethod: value })
              .then(({ data }) => {
                if (data.emailToCode || data.phoneNumber) {
                  this.confirmEmailAndPhone(value, (value === 'email' ? emailToCode : phoneNumber));
                } else if (!data.ok) this.showMessage();
              })
              .catch(err => this.$validator.errors.add({ msg: err, field: 'email' }))
              .then(() => this.$store.commit('formLoading', false))
          }
        }
      })
    },
    confirmEmailAndPhone(selected, valueSelected) {
      Swal.fire({
        title: 'Código enviado',
        text: `Digite o código enviado para o ${selected === 'phone' ? 'celular' : 'e-mail'} ${valueSelected}`,
        input: 'password',
        inputPlaceholder: 'Código de 6 dígitos',
        inputAttributes: {
          maxlength: 6,
          autocapitalize: 'off',
          autocorrect: 'off'
        },
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        closeOnConfirm: false,
        closeOnCancel: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
        preConfirm: async (value) => {
          const userDocumentType = this.user.tipo === 'PF' ? 'cpf' : 'cnpj';
          this.$http.post((`${process.env.VUE_APP_HUB_BASE_URL2}/users/login`), { email: this.user[userDocumentType], senha: this.user.senha, code: value })
            .then(({ data }) => {
              if (data.token) {
                if (localStorage.getItem('cardToUnlock')) localStorage.removeItem('cardToUnlock')
                this.$cookies.set('token', data.token, '30min');
                this.showMessage();
              } else if (!data.ok) this.showMessage();
            })
            .catch(err => this.$validator.errors.add({ msg: err, field: 'email' }))
            .then(() => this.$store.commit('formLoading', false))
        }
      })
    },
    showMessage() {
      Swal.fire({
        title: 'Importante',
        text: 'Desbloqueio realizado com sucesso. Seu crédito poderá ficar disponível em até 48h úteis, caso isto não ocorra, favor contatar sua empresa contratante/pagadora para verificação.',
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Entendi',
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        closeOnConfirm: false,
        closeOnCancel: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
        preConfirm: async () => {
          this.$router.push({ name: 'my-cards' });
        }
      })
    },
    async postCreateUser(addressUrlApi, userData) {
      await this.$http.post(`${addressUrlApi}?type=${this.user.tipo}`, userData)
        // eslint-disable-next-line no-unused-vars
        .then(async () => {
          this.codeSended = '';
          Swal.fire({
            title: 'Sucesso',
            text: 'O seu cartão foi desbloqueado com sucesso. Você será redirecionado para a página de login.',
            type: 'success',
            showCancelButton: false,
            confirmButtonText: 'Entendi',
            allowOutsideClick: false
          }).then(() => {
            this.$router.push({ name: 'signIn' });
          });
        })
        .catch((err) => {
          Swal.fire({
            title: 'Oops!',
            text: err.message || 'Não foi possível concluir a operação nesse momento, tente novamente mais tarde',
            icon: 'error',
            confirmButtonText: 'Voltar'
          });
          return false;
        });
    },
    acceptTerms() {
      this.showTerms = false
      this.showConditions = false
      this.signUp()
    },
    getCardNumberFromCache() {
      const cardNumber = localStorage.getItem('cardNumber');
      return cardNumber;
    },
    validatePassword() {
      const password = this.user.senha;
      // return (password === this.user.confirmaSenha
      //         && /[A-Z]+/.test(password) // has one capital letter
      //         && /[\w]+/.test(password) // has one letter
      //         && /\d+/.test(password) // has one number
      //         && /[\`\~\!\@\#\$\%\^\&\*\(\)\-\_\+\=\[\]\{\}\\\|\;\:\'\"\,\.\<\>\/\?]/.test(password) // special character
      //         && /^\d{8,9}$/.test(this.user.telefones.numeroTelefone));

      return (!!password
        && password === this.user.confirmaSenha
        && /[\w]+/.test(password) // has one letter
        && /\d+/.test(password) // has one number
        && /(.*?[\W_].*?){2,}/.test(password) // has two special characters
        && /[a-z]+/.test(password) // has one lowercase letter
        && /[A-Z]+/.test(password) // has one capital letter
        && password.length >= 10);
    },
    showErrors(err) {
      const error = JSON.stringify(err);
      const errors = {
        withoutName: 'É necessário informar o nome',
        wrongName: 'O nome informado não corresponde ao encontrado'
      }
      if (error.toLowerCase().indexOf('nome informado não corresponde') >= 0
        || error.includes(errors.wrongName)) {
        this.$validator.errors.add({ msg: error, field: 'account.name' });
      } else if (error.toLowerCase().indexOf('mãe') >= 0) {
        this.$validator.errors.add({ msg: error, field: 'account.mothersName' });
      } else if (error.toLowerCase().indexOf('nascimento') >= 0
        || error.toLowerCase().indexOf('birth') >= 0) {
        this.$validator.errors.add({ msg: error, field: 'account.birthDate' });
      } else if (error.toLowerCase().indexOf('cpf') >= 0
        || error.toLowerCase().indexOf('Documento') >= 0) {
        this.$validator.errors.add({ msg: error, field: 'account.cpf' });
      } else if (error.toLowerCase().indexOf('cartão já atribuído a pessoa') >= 0) {
        // Isso é necessário por causa da atualização da API para criar usuários
        this.$router.push({ name: 'signIn' });
        // validação do telefone
      }
    },
  },
};
</script>

<template>
  <div class="sign-up">
    <div class="sign-up__header">
      <v-icon class="sign-up__header__logo" name="logo" />
      <p class="sign-up__header__title">
        Cadastre-se
      </p>
      <p class="sign-up__header__info">
        Preencha o formulário com os seus dados
      </p>
    </div>
    <form @submit.prevent="signUp" ref="formSign" class="sign-up__form">
      <v-user-account :data="user" />
      <div class="sign-up__form__step">
        <div class="sign-up__form__step__title">
          Dados de acesso
        </div>
        <div class="sign-up__form__step__fieldset">
          <v-textbox
            v-model="user.email"
            class="sign-up__form__step__fieldset__field-full"
            data-vv-as="Email"
            label="Email:"
            name="email"
            placeholder="nome@email.com.br"
            type="email"
            validate="required|email"
            :disabled="isEmailDisabled"
          />
          <v-textbox
            v-model="user.email"
            :validate="`required|email|confirmed:email`"
            class="sign-up__form__step__fieldset__field-full"
            data-vv-as="email"
            label="Confirmar o email:"
            name="emailConfirmation"
            placeholder="Digite o email novamente"
            type="email"
            :disabled="isConfirmEmailDisabled"
          />
          <v-user-password :data="user" />
          <div class="terms">
            <input
              type="checkbox"
              title="Para continuar você deve aceitar os termos"
              required
            />
            Eu aceito a
            <a href="" @click.prevent="showTerms = true"
              >Política de privacidade</a
            >
            e os
            <a href="" @click.prevent="showConditions = true"
              >Termos e Condições de Uso</a
            >
            da OnlyPay.
          </div>
          <div class="sign-up__form__step__fieldset__actions">
            <v-button
              class="sign-up__form__step__fieldset__actions__submit"
              label="Avançar"
            />
          </div>
        </div>
      </div>
    </form>
    <div class="sign-up__form__footer">
      <p class="sign-up__form__footer__copywrite">
        © 2018 OnlyPay - Soluções de pagamento
      </p>
      <p class="sign-up__form__footer__contact">
        <a href="tel:551138424693">(11) 3842-4693</a>
        &bull;
        <a href="mailto:faleconosco@onlypay.com.br"
          >faleconosco@onlypay.com.br</a
        >
      </p>
    </div>

    <transition v-if="showTerms" name="fade">
      <terms @close="showTerms = false" @accept="acceptTerms" />
    </transition>
    <transition v-if="showConditions" name="fade">
      <Conditions @close="showConditions = false" @accept="acceptTerms" />
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
.sign-up {
  @media (min-width: 992px) {
    background-color: var(--color-blue-medium);
  }
  .terms {
    input {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    a {
      color: var(--color-green);
      display: inline-block;
      vertical-align: middle;
    }
  }
  &__header {
    background-color: var(--color-blue-medium);
    color: var(--color-white);
    padding: 1.5rem;
    text-align: center;
    &__logo {
      margin: 1rem 0 2.5rem;
      size: 6rem 2.85875rem;
    }
    &__title {
      font-size: 1.5rem;
      font-weight: 300;
      margin-bottom: 0.25rem;
    }
    &__info {
      font-size: 0.875rem;
    }
  }
  &__form {
    padding: 1.5rem;
    @media (min-width: 992px) {
      background-color: var(--color-white);
      border-radius: 0.5rem;
      margin: 0 auto;
      padding: 3rem;
      width: 976px;
    }
    &__step {
      @media (min-width: 992px) {
        display: flex;
      }
      &__title {
        font-size: 1.5rem;
        font-weight: 300;
        @media (min-width: 992px) {
          margin-right: 6rem;
          width: 342px;
        }
      }
      &__fieldset {
        margin-top: 1.5rem;
        @media (min-width: 992px) {
          margin-top: 0;
          width: 538px;
        }
        &__actions {
          padding-top: 1.5rem;
        }
      }
    }
    &__footer {
      background-color: var(--color-black);
      color: var(--color-white);
      padding: 1.5rem;
      @media (min-width: 992px) {
        background: none;
        margin: 0 auto;
        padding: 1.5rem 0;
        width: 976px;
        &:after {
          content: "";
          display: block;
          clear: both;
        }
      }
      &__copywrite {
        font-size: 0.75rem;
        margin-bottom: 1rem;
        @media (min-width: 992px) {
          float: left;
          margin-bottom: 0;
        }
      }
      &__contact {
        font-size: 0.875rem;
        a {
          color: inherit;
        }
        @media (min-width: 992px) {
          float: right;
        }
      }
    }
  }
}
</style>

<style lang="postcss">
.swal2-radio {
  display: block !important;
  label {
    display: block;
    text-align: left;
  }
  input {
    vertical-align: baseline;
  }
}
.textbox {
  display: flex;
  flex-direction: column;
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
  &__label {
    color: var(--color-blue-onlypay);
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0.3rem;
  }
  &__input {
    background-color: var(--color-white);
    padding: 1.1rem 2rem 1rem 1.5rem;
    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
    &[name="password"] {
      &[type="text"] {
        -webkit-text-security: disc;
      }
    }
  }
  &__view-password {
    top: 3rem !important;
    right: 2rem !important;
  }
}
</style>
