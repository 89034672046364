var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sign-up" },
    [
      _c(
        "div",
        { staticClass: "sign-up__header" },
        [
          _c("v-icon", {
            staticClass: "sign-up__header__logo",
            attrs: { name: "logo" },
          }),
          _c("p", { staticClass: "sign-up__header__title" }, [
            _vm._v("\n      Cadastre-se\n    "),
          ]),
          _c("p", { staticClass: "sign-up__header__info" }, [
            _vm._v("\n      Preencha o formulário com os seus dados\n    "),
          ]),
        ],
        1
      ),
      _c(
        "form",
        {
          ref: "formSign",
          staticClass: "sign-up__form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.signUp.apply(null, arguments)
            },
          },
        },
        [
          _c("v-user-account", { attrs: { data: _vm.user } }),
          _c("div", { staticClass: "sign-up__form__step" }, [
            _c("div", { staticClass: "sign-up__form__step__title" }, [
              _vm._v("\n        Dados de acesso\n      "),
            ]),
            _c(
              "div",
              { staticClass: "sign-up__form__step__fieldset" },
              [
                _c("v-textbox", {
                  staticClass: "sign-up__form__step__fieldset__field-full",
                  attrs: {
                    "data-vv-as": "Email",
                    label: "Email:",
                    name: "email",
                    placeholder: "nome@email.com.br",
                    type: "email",
                    validate: "required|email",
                    disabled: _vm.isEmailDisabled,
                  },
                  model: {
                    value: _vm.user.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "email", $$v)
                    },
                    expression: "user.email",
                  },
                }),
                _c("v-textbox", {
                  staticClass: "sign-up__form__step__fieldset__field-full",
                  attrs: {
                    validate: `required|email|confirmed:email`,
                    "data-vv-as": "email",
                    label: "Confirmar o email:",
                    name: "emailConfirmation",
                    placeholder: "Digite o email novamente",
                    type: "email",
                    disabled: _vm.isConfirmEmailDisabled,
                  },
                  model: {
                    value: _vm.user.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "email", $$v)
                    },
                    expression: "user.email",
                  },
                }),
                _c("v-user-password", { attrs: { data: _vm.user } }),
                _c("div", { staticClass: "terms" }, [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      title: "Para continuar você deve aceitar os termos",
                      required: "",
                    },
                  }),
                  _vm._v("\n          Eu aceito a\n          "),
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.showTerms = true
                        },
                      },
                    },
                    [_vm._v("Política de privacidade")]
                  ),
                  _vm._v("\n          e os\n          "),
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.showConditions = true
                        },
                      },
                    },
                    [_vm._v("Termos e Condições de Uso")]
                  ),
                  _vm._v("\n          da OnlyPay.\n        "),
                ]),
                _c(
                  "div",
                  { staticClass: "sign-up__form__step__fieldset__actions" },
                  [
                    _c("v-button", {
                      staticClass:
                        "sign-up__form__step__fieldset__actions__submit",
                      attrs: { label: "Avançar" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._m(0),
      _vm.showTerms
        ? _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c("terms", {
                on: {
                  close: function ($event) {
                    _vm.showTerms = false
                  },
                  accept: _vm.acceptTerms,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showConditions
        ? _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c("Conditions", {
                on: {
                  close: function ($event) {
                    _vm.showConditions = false
                  },
                  accept: _vm.acceptTerms,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sign-up__form__footer" }, [
      _c("p", { staticClass: "sign-up__form__footer__copywrite" }, [
        _vm._v("\n      © 2018 OnlyPay - Soluções de pagamento\n    "),
      ]),
      _c("p", { staticClass: "sign-up__form__footer__contact" }, [
        _c("a", { attrs: { href: "tel:551138424693" } }, [
          _vm._v("(11) 3842-4693"),
        ]),
        _vm._v("\n      •\n      "),
        _c("a", { attrs: { href: "mailto:faleconosco@onlypay.com.br" } }, [
          _vm._v("faleconosco@onlypay.com.br"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }